import React, { useState } from "react";
import Dilog from "./Dilog";
export default function Preise() {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <div className=" py-10 px-4">
        <div className="max-w-[1100px] ml-auto mr-auto">
          <h2 className="text-3xl f-f-b text-primary text-center mt-16   ">
            Preise
          </h2>
          <p className=" text-primary f-f-b text-tiny text-center ">
            auf Anfrage
          </p>
          <div className=" px-5 sm:px-10 py-20 bg-primary-lightbg my-10 ">
            <div className="grid grid-cols-12 sm:gap-8  ">
              <div className="  col-span-12 md:col-span-6">
                <h2 className="text-2xl sm:text-3xl f-f-b text-primary leading-[50px]">
                  Fordern Sie jetzt eine Demo an!
                </h2>
              </div>
              <div className="  col-span-12 md:col-span-6">
                <div className="text-center">
                  <button
                    onClick={() => setShowModal(true)}
                    class="f-f-b text-lg bg-primary-blue  rounded-lg  hover:bg-primary text-white px-10 py-5 my-5 sm:my-10   "
                  >
                    Jetzt Demo anfordern
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showModal ? <Dilog setShowModal={setShowModal} /> : null}
    </>
  );
}
