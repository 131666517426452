import React, { useState } from "react";
import Undraw from "../../assets/images/undraw_web_developer_re_h7ie(1).png";
import QR from "../../assets/images/QR-Code.png";
import Trends from "../../assets/images/undraw_data_trends_re_2cdy(1).png";

export default function Funktioniert() {
  const [value, setValue] = React.useState("one");

  const handleChange = (data) => {
    setValue(data);
  };
  console.log(value);
  return (
    <>
      <div className="py-20 px-4">
        <div className="max-w-[1100px] ml-auto mr-auto">
          <h1 class=" text-2xl md:text-3xl text-primary f-f-b text-center ">
            So funktioniert's
          </h1>

          <div className="grid grid-cols-12 gap-8 mt-10 ">
            <div className="  col-span-12 md:col-span-6">
              {value === "one" && (
                <img
                  src={Undraw}
                  width="100%"
                  height="auto"
                  className="  sm:mt-36 lg:mt-0"
                />
              )}
              {value === "two" && (
                <img
                  src={QR}
                  width="80%"
                  height="auto"
                  className="  sm:mt-36 lg:mt-12 ml-auto mr-auto "
                />
              )}
              {value === "three" && (
                <img
                  src={Trends}
                  width="100%"
                  height="auto"
                  className="  sm:mt-36 lg:mt-0"
                />
              )}
            </div>
            <div className="col-span-12 md:col-span-6">
              <div>
                <button
                  className={`${
                    value === "one" ? "bg-primary-lightbg" : "bg-white"
                  }  p-4 rounded-lg w-full text-left`}
                  onClick={() => handleChange("one")}
                >
                  <ul className=" inline-flex ">
                    <li>
                      <div
                        className={`${value === "one" ? "ble-one" : "ble-two"}`}
                      >
                        <div className=" text-lg f-f-m  text-white  text-center">
                          1
                        </div>
                      </div>
                    </li>
                    <li className="ml-4">
                      <h2 className=" text-primary text-left  text-base f-f-b  ">
                        Erstellen Sie die Umfrage online
                      </h2>
                      <h3 className=" text-primary text-left  text-base f-f-r mt-3 ">
                        Bestimmen Sie, was Sie von den Patienten wissen möchten
                        und passen Sie die Fragen bei Bedarf einfach an.
                      </h3>
                    </li>
                  </ul>
                </button>
                <button
                  className={`${
                    value === "two" ? "bg-primary-lightbg" : "bg-white"
                  }  p-4 rounded-lg w-full text-left mt-4 `}
                  onClick={() => handleChange("two")}
                >
                  <ul className=" inline-flex ">
                    <li>
                      <div
                        className={`${value === "two" ? "ble-one" : "ble-two"}`}
                      >
                        <div className=" text-lg f-f-m  text-white  text-center">
                          2
                        </div>
                      </div>
                    </li>
                    <li className="ml-4">
                      <h2 className=" text-primary text-left  text-base f-f-b  ">
                        Laden Sie den QR-Code für die Umfrage herunter
                      </h2>
                      <h3 className=" text-primary text-left  text-base f-f-r mt-3 ">
                        Den QR-Code können Sie beim Empfang, auf Visitenkarten
                        und auf Rechnungen platzieren.
                      </h3>
                    </li>
                  </ul>
                </button>
                <button
                  className={`${
                    value === "three" ? "bg-primary-lightbg" : "bg-white"
                  }  p-4 rounded-lg w-full text-left mt-4 `}
                  onClick={() => handleChange("three")}
                >
                  <ul className=" inline-flex ">
                    <li>
                      <div
                        className={`${
                          value === "three" ? "ble-one" : "ble-two"
                        }`}
                      >
                        <div className=" text-lg f-f-m  text-white  text-center">
                          3
                        </div>
                      </div>
                    </li>
                    <li className="ml-4">
                      <h2 className=" text-primary text-left  text-base f-f-b  ">
                        Gewinnen Sie Erkenntnisse aus den Feeedbacks
                      </h2>
                      <h3 className=" text-primary text-left  text-base f-f-r mt-3 ">
                        Ein übersichtliches Dashboard mit Tabellen & Grafiken
                        ermöglicht eine strukturierte Analyse.
                      </h3>
                    </li>
                  </ul>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
