import React from "react";
import Yopinioslick from "./Yopinioslick";
export default function Yopinio() {
  return (
    <div className="max-w-[1100px] ml-auto mr-auto my-10 px-4">
      <div className="yopinio p-7 sm:p-14 relative ">
        <div className="grid grid-cols-12 md:gap-10  ">
          <div className="  col-span-12 md:col-span-6">
            <h2 className="text-primary text-2xl lg:text-3xl f-f-b  leading-[50px] mt-10 sm:mt-20 ">
              {" "}
              Yopinio macht Arztpraxen erfolgreicher
            </h2>
          </div>
          <div className="  col-span-12 md:col-span-6">
            <div className="w-11/12   mt-16 ">
              <Yopinioslick />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
