import React from "react";
import Slider from "react-slick";
import Arrowup from "../../assets/images/Group 2.png";
import Arrowdown from "../../assets/images/Group 1.png";
// import  {AiOutlineArrowRight } from 'react-icons/bi';

export default function Yopinioslick() {
  const settings = {
    dots: false,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <img src={Arrowup} width="40px" height="auto" />,

    prevArrow: <img src={Arrowdown} width="40px" height="auto" />,
  };
  return (
    <div>
      <Slider {...settings} className="slider-setting">
        <div>
          <h2 className="text-primary text-lg f-f-r  ">
            Wir hatten teilweise negative Bewertungen auf Google Maps und
            anderen online Plattformen. Mit Yopinio bekommen wir das Feedback
            direkt von den Patienten und können reagieren, bevor die Bewertungen
            online für alle einsehbar sind. Wir konnten mit Yopinio die
            Patientenzufriedenheit und die öffentlichen Bewertungen erhöhen.
          </h2>
          <h3 className="text-primary text-lg f-f-b mt-8  ">
            - Zahnarztpraxis in Zürich
          </h3>
        </div>
        <div>
          <h2 className="text-primary text-lg f-f-r  ">
            {" "}
            Die meisten Patienten geben aktiv kein Feedback, auch wenn Ihre
            Meinung sehr wichtig für uns wäre. Mit Yopinio konnten wir die
            Hemmschwelle senken, wir erhalten regelmässig wertvolle Inputs die
            wir mit den jeweiligen Ärzten in den Mitarbeitergesprächen
            besprechen. So konnten wir beispielsweise die Kommunikation
            entscheidend verbessern.
          </h2>
          <h3 className="text-primary text-lg f-f-b mt-8  ">
            - Allgemeinmedizinische Klink in Basel-Stadt
          </h3>
        </div>
        <div>
          <h2 className="text-primary text-lg f-f-r  ">
            Ein riesiges Dankeschön an Yopinio! Uns war nicht bewusst, dass es
            Patienten gab die wegen der langen Wartezeiten nicht mehr zu uns
            kommen wollten. Wir haben die Wartezeiten senken können und die
            Patientenzufriedenheit ist erheblich gestiegen, was wir auch in den
            öffentlichen Bewertungen bestätigt bekommen.
          </h2>
          <h3 className="text-primary text-lg f-f-b mt-8  ">
            - Gruppenpraxis in Zürich
          </h3>
        </div>
      </Slider>
    </div>
  );
}
