import React, { useState } from "react";
import Logo from "../../assets/images/Yopinio Logo New_blue background.png";
import Dilog from "./Dilog";
import Feedback from "./Feedback";
import Einfach from "./Einfach";
import Funktioniert from "./funktioniert";
import Yopinio from "./Yopinio";
import Preise from "./Preise";
import Footer from "./Footer";

export default function ButtonAppBar() {
  const [navbar, setnavbar] = useState(false);
  const [showModal, setShowModal] = useState(false);
  return (
    <div>
      <nav className="bg-primary-lightbg  px-2 sm:px-4 py-2.5 rounded  fixed top-0 w-full z-10  ">
        <div
          className="max-w-[1100px]  ml-auto mr-auto flex flex-wrap justify-between items-center mx-auto"
          bis_skin_checked="1"
        >
          <a href="/" className="flex items-center">
            <img
              src={Logo}
              className=" w-36 lg:w-40 h-auto "
            />
          </a>
          <button
            data-collapse-toggle="navbar-default"
            onClick={() => {
              setnavbar(!navbar);
            }}
            type="button"
            className="inline-flex items-center p-2 ml-3 text-xl  rounded-lg md:hidden  "
            aria-controls="navbar-default"
            aria-expanded="false"
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-7 h-7"
              aria-hidden="true"
              fill="#111d57"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </button>
          <div
            className={`${
              navbar ? "block" : "hidden"
            } w-full md:block md:w-auto`}
            id="navbar-default"
            bis_skin_checked="1"
          >
            <ul className="flex flex-col   py-4 md:py-2  mt-4 bg-white md:bg-transparent  md:flex-row md:space-x-3 lg:space-x-6  md:mt-0 md:text-sm md:font-medium md:border-0  ">
              <li>
                <a
                onClick={() => {
                  setnavbar(!navbar);
                }}
                  href="#Einfach"
                  className="block py-2 pr-4 pl-3  f-f-b border-b md:border-b-0 border-grey   text-tiny    rounded md:bg-transparent text-primary md:hover:text-primary-light  "
                  aria-current="page"
                >
                  Vorteile
                </a>
              </li>
              <li>
                <a
                onClick={() => {
                  setnavbar(!navbar);
                }}
                  href="#Funktioniert"
                  className="block py-2 pr-4 pl-3  f-f-b  border-b md:border-b-0 border-grey  text-tiny  text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:text-primary md:hover:text-primary-light  "
                >
                  Funktion
                </a>
              </li>
              <li>
                <a
                onClick={() => {
                  setnavbar(!navbar);
                }}
                  href="#Yopinio"
                  className="block py-2 pr-4 pl-3  f-f-b  border-b md:border-b-0 border-grey   text-tiny  text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:text-primary md:hover:text-primary-light  "
                >
                  Kunden
                </a>
              </li>
              <li>
                <a
                onClick={() => {
                  setnavbar(!navbar);
                }}
                  href="#Preise"
                  className="block py-2 pr-4 pl-3  f-f-b   border-b md:border-b-0 border-grey text-tiny  text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:text-primary md:hover:text-primary-light  "
                >
                  Preise
                </a>
              </li>
              <li>
                <button
                  onClick={() => {
                    setnavbar(!navbar)
                    setShowModal(true)
                    }}
                  className="block py-2   f-f-b    text-tiny  text-gray-700 rounded-lg  md:bg-primary-blue md:hover:bg-primary  md:border-0 md:text-white md:hover:text-white md:py-3 px-3 md:px-5 "
                >
                  Kostenlos starten
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      {/*  dilog box testing */}
      {showModal ? <Dilog setShowModal={setShowModal} /> : null}

      {/* dilog box testing ended */}

      <Feedback />
      <div id="Einfach">
        <Einfach />
      </div>
      <div id="Funktioniert">
        <Funktioniert />
      </div>
      <div id="Yopinio">
        <Yopinio />
      </div>
      <div id="Preise">
        <Preise />
      </div>
      <Footer />
    </div>
  );
}
