import React from "react";
import Logo from "../../assets/images/Yopinio Logo New_blue background.png";
export default function Footer() {
  return (
    <div className="py-10 px-4">
      <div className="max-w-[1100px] ml-auto mr-auto">
        <div className="grid grid-cols-12 gap-4 sm:gap-8  ">
          <div className="  col-span-12 md:col-span-3 ">
            <img src={Logo} width="150px" height="auto" />
            <h2 className=" text-primary text-base f-f-r  my-4 ">
              Patientenfeedback für Arztpraxen
            </h2>
          </div>
          <div className="  col-span-12 md:col-span-3 ">
            <h3 className=" text-primary text-base f-f-b mt-3  ">Navigation</h3>
            <ul>
              <li className="mt-2">
                <a
                  href="#Einfach"
                  className=" text-primary text-base f-f-r   cursor-pointer  "
                >
                  Vorteile
                </a>
              </li>
              <li className="mt-2">
                <a
                  href="#Funktioniert"
                  className=" text-primary text-base f-f-r   cursor-pointer  "
                >
                  Funktionen
                </a>
              </li>
              <li className="mt-2">
                <a
                  href="#Yopinio"
                  className=" text-primary text-base f-f-r   cursor-pointer  "
                >
                  Kunden
                </a>
              </li>
              <li className="mt-2">
                <a
                  href="#Preise"
                  className=" text-primary text-base f-f-r   cursor-pointer  "
                >
                  Preise
                </a>
              </li>
            </ul>
          </div>
          <div className=" col-span-12 md:col-span-3 ">
            <h3 className="text-primary text-base f-f-b mt-3  ">Kontakt ☎️</h3>
            <ul>
              <li className="mt-2">
                <a
                  href="mailto:info@yopinio.ch"
                  className=" text-primary text-base f-f-r cursor-pointer  "
                >
                  info@yopinio.ch
                </a>
              </li>
              <li className="mt-2 text-primary text-base">
                  +41 44 797 59 12
              </li>
              <li className="mt-2 text-primary text-base">
                  Northstar GmbH
              </li>
              <li className="mt-2 text-primary text-base">
                  Alte Zürcherstrasse 68<br/>
                  CH-8903 Birmensdorf
              </li>
            </ul>
          </div>
          <div className=" col-span-12 md:col-span-3 ">
            <h3 className="text-primary text-base f-f-b mt-3  ">Projekte</h3>
            <ul>
              <li className="mt-2">
                <a
                  href="https://www.northstar-digital.ch"
                  className=" text-primary text-base f-f-r cursor-pointer  "
                >
                  Northstar Digital Ventures
                </a>
              </li>
              <li className="mt-2 text-primary text-base">
                  <a
                  href="https://www.heymia.ch"
                  className=" text-primary text-base f-f-r cursor-pointer  "
                >
                  heyMia
                </a>
              </li>
              <li className="mt-2 text-primary text-base">
                  <a
                  href="https://www.wunderpick.ch"
                  className=" text-primary text-base f-f-r cursor-pointer  "
                >
                  wunderpick
                </a>
              </li>
            </ul>
          </div>
        </div>
        <h2 className=" text-primary text-base f-f-r   mt-4">
          In Züri baut, für d Wält ❤️{" "}
        </h2>
      </div>
    </div>
  );
}
