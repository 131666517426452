import React from 'react'
import Header from './header'


export default function index() {
    return (
        <div>
            
         <Header/>
    
        </div>
    )
}
