import React from "react";

export default function Dilog({ setShowModal }) {
  return (
    <>
      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div
          className="fixed inset-0 w-full h-full bg-black opacity-40"
          onClick={() => setShowModal(false)}
        ></div>
        <div className="flex items-center min-h-screen px-4 py-8">
          <div className="relative w-full max-w-xs p-4 mx-auto bg-white rounded-md shadow-lg">
            <button
              type="button"
              onClick={() => setShowModal(false)}
              class="text-gray-400  rounded-lg text-sm p-1.5 ml-auto inline-flex items-center absolute right-3 top-3 "
              data-modal-toggle="defaultModal"
            >
              <svg
                aria-hidden="true"
                class="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span class="sr-only">Close modal</span>
            </button>
            <div className="mt-3 sm:flex">
              <div className="mt-2 text-center sm:ml-4 sm:text-left">
                <h4 className="text-xl f-f-b-m text-primary">Registrierung</h4>
                <p className="my-4 text-grey-dark f-f-r text-tiny ">
                  Nehmen Sie gerne mit uns per E-Mail Kontakt auf. Wir zeigen
                  Ihnen das Produkt gerne persönlich.
                </p>
                <a href="mailto:info@yopinio.ch" className="my-4 text-black f-f-b text-base cursor-pointer ">
                  info@yopinio.ch
                </a>
                <div className="items-center gap-2 mt-3 sm:flex">
                  <button
                    className="w-full mt-2 py-4 flex-1 f-f-b    text-tiny bg-primary-blue  rounded-md  hover:bg-primary text-white"
                    onClick={() => setShowModal(false)}
                  >
                    Schliessen
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
