import React from "react";
import PreviewIcon from "../../assets/images/PreviewIcon.png";
import TimerIcon from "../../assets/images/TimerIcon.png";
import ImageIcon from "../../assets/images/ImageIcon.png";

export default function Einfach() {
  return (
    <div className="py-20 px-4">
      <div className="max-w-[1100px] ml-auto mr-auto">
        <h1 className="max-w-[1050px] text-2xl md:text-3xl text-primary f-f-b text-center ">
          Einfach ehrliches Patientenfeedback sammeln
        </h1>
      </div>
      <div className="max-w-[1100px] ml-auto mr-auto">
        <div className="grid grid-cols-12 gap-4 mt-16 ">
          {/*  start */}
          <div className="  col-span-12 md:col-span-4">
            <div className="text-center bg-primary-lightbg  rounded-full w-32 h-32  ml-auto mr-auto ">
              <img src={PreviewIcon} className="ml-auto mr-auto pt-7  " />
            </div>
            <h2 className=" text-lg f-f-b text-primary  text-center my-3 ">
              ungefiltertes Patientenfeedback
            </h2>
            <h4 className=" text-tiny text-center text-primary f-f-r ">
              Erfahren Sie direkt, was Ihre Patienten wirklich denken &
              verbessern Sie Ihre Praxis.
            </h4>
          </div>
          {/*  end */}
          {/*  start */}
          <div className="  col-span-12 md:col-span-4">
            <div className="text-center bg-primary-lightbg  rounded-full w-32 h-32  ml-auto mr-auto ">
              <img src={TimerIcon} className="ml-auto mr-auto pt-7  " />
            </div>
            <h2 className=" text-lg f-f-b text-primary  text-center my-3 ">
              keine Hardware erforderlich
            </h2>
            <h4 className=" text-tiny text-center text-primary f-f-r ">
              Patienten geben Ihnen mittels QR-Code mit dem eigenen Smartphone
              Feedback.
            </h4>
          </div>
          {/*  end */}
          {/*  start */}
          <div className="  col-span-12 md:col-span-4">
            <div className="text-center bg-primary-lightbg  rounded-full w-32 h-32  ml-auto mr-auto ">
              <img src={ImageIcon} className="ml-auto mr-auto pt-7  " />
            </div>
            <h2 className=" text-lg f-f-b text-primary  text-center my-3 ">
              digitale Analysen
            </h2>
            <h4 className=" text-tiny text-center text-primary f-f-r ">
              Sämtliche Feedbacks sind nur für Sie jederzeit über das intuitive
              Web-Interface einsehbar.
            </h4>
          </div>
          {/*  end */}
        </div>
      </div>
    </div>
  );
}
