import Home from './components/home/index'
function App() {
  return (
    <div >
      <Home/>
    </div>
  );
}

export default App;
