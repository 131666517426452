import React, { useState } from "react";
import Review from "../../assets/images/Review.png";
import Dilog from "./Dilog";
export default function Feedback() {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <div className="bg-primary-lightbg py-10 px-4">
        <div className="max-w-[1100px]  ml-auto mr-auto">
          <div className="grid grid-cols-12 md:gap-8 mt-4 ">
            <div className="  col-span-12 md:col-span-5">
              <h1 className=" text-primary text-3xl xl:text-5xl f-f-b  mb-8 mt-20 md:mt-40 md:mb-8  leading-[50px] md:leading-[60px] ">
                Feedback für Arztpraxen
              </h1>
              <h2 className=" text-primary text-tiny lg:text-lg f-f-r   ">
                Verbessern Sie Ihre Google-Bewertungen! Fragen Sie Ihre
                Patienten aktiv nach Feedback. Anonym, ohne Hardware, günstig
                und einfach.
              </h2>

              <button
                onClick={() => setShowModal(true)}
                className="   f-f-b    text-lg bg-primary-blue  rounded-lg  hover:bg-primary text-white px-10 py-5 my-10  "
              >
                Jetzt Demo anfordern
              </button>
            </div>
            <div className="  col-span-12 md:col-span-7">
              <img
                src={Review}
                width="331px"
                height="auto"
                className="ml-auto mr-auto mt-5 md:mt-36"
              />
            </div>
          </div>
        </div>
      </div>
      {/*  dilog box testing */}
      {showModal ? <Dilog setShowModal={setShowModal} /> : null}
    </>
  );
}
